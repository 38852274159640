import React, { ReactNode } from "react"
import { ActivityIndicator, StyleSheet, View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { Icon } from "../display/Icon"
import { MCIcon } from "../display/MCIcon"
import { useColor } from "../hooks/useColor"
import { Box } from "../layout/Box"
import { Column } from "../layout/FlexBox"
import { Color } from "../types"
import { Label } from "../typography/Label"
import { ButtonProps } from "./Button"
import { PressableOpacity } from "./PressableOpacity"

export type IconButtonProps = Pick<
  ButtonProps,
  "pending" | "disabled" | "onPress"
> & {
  // The icon that should be displayed with the button.
  icon: MCIcon

  // Specifies how large the displayed icon should be.
  size: "XS" | "S" | "M" | "L" | "XL"

  // Optional label that will be placed bellow the icon.
  text?: string

  // The color of the label that is displayed bellow the icon.
  // Defaults to 'background'
  textColor?: Color

  // The color of the background of the button.
  // Defaults to 'primary'
  backgroundColor?: Color

  // Element to be displayed
  // in the top right corner of the button
  // (will always be overwritten by pending - ActivityIndicator).
  cornerElement?: ReactNode
}

export function IconButton(props: IconButtonProps) {
  const labelColor = props.textColor ?? "background"
  const buttonColor = useColor(props.backgroundColor ?? "primary")

  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)
  const isDisabled = props.disabled || props.pending || !props.onPress

  const cornerElement = (props.pending || props.cornerElement) && (
    <CornerElement {...props} textColor={labelColor} />
  )

  const icon = <Icon size={props.size} name={props.icon} color={labelColor} />
  const label = props.text ? (
    <Label bold singleLine text={props.text} color={labelColor} />
  ) : null

  const button = (
    <View style={isHovered && styles.hovered}>
      <Column
        alignCenter
        pad="XXXS"
        disabled={isDisabled}
        style={styles.buttonContainer}
        backgroundColor={buttonColor}
        onPress={props.onPress}
      >
        {icon}
        {label}
      </Column>
    </View>
  )

  return (
    <PressableOpacity
      disabled={isDisabled}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
    >
      {cornerElement}
      {button}
    </PressableOpacity>
  )
}

function CornerElement(props: IconButtonProps) {
  const pendingColor = useColor(props.textColor)

  return (
    <Box style={styles.cornerElement}>
      {props.pending && <ActivityIndicator color={pendingColor} />}
      {!props.pending && props.cornerElement}
    </Box>
  )
}

const styles = StyleSheet.create({
  hovered: { opacity: 0.8 },
  buttonContainer: { zIndex: 1, borderRadius: 6 },
  cornerElement: { top: 5, right: 5, zIndex: 2, position: "absolute" },
})
