import React, { ReactNode, RefObject, useMemo } from "react"
import {
  Platform,
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  View,
  ViewStyle,
} from "react-native"

export type PressableOpacityProps = Pick<
  PressableProps,
  | "disabled"
  | "delayLongPress"
  | "onPress"
  | "onPressIn"
  | "onPressOut"
  | "onLongPress"
  | "onHoverIn"
  | "onHoverOut"
> & {
  children?: ReactNode
  ref?: RefObject<View>
  alwaysActive?: boolean
  style?: StyleProp<ViewStyle>
}

export function PressableOpacity({
  ref,
  children,
  alwaysActive,
  delayLongPress,
  onPress,
  onPressIn,
  onPressOut,
  onLongPress,
  onHoverIn,
  onHoverOut,
  ...props
}: PressableOpacityProps) {
  // The component's logic (press, hover, etc.) should be disabled
  // when either explicitly requested by an implementing component OR
  // when there are no supported handlers specified
  // that would handle input events.
  const disabled =
    props.disabled ||
    !(
      onPress ||
      onPressIn ||
      onPressOut ||
      onLongPress ||
      onHoverIn ||
      onHoverOut
    )

  // Compose the desired and potentially dynamically
  // adjusting style for the component.
  const style = useMemo(
    () =>
      !alwaysActive
        ? ({ pressed }: PressableStateCallbackType) => [
            { opacity: pressed && !disabled ? 0.6 : 1 },
            props.style,
          ]
        : props.style,
    [alwaysActive, disabled, props.style],
  )

  // For harmonisation purposes,
  // create the general props as an object too.
  const generalProps = { ref, style, disabled }

  // As onMouseEnter and onMouseLeave are not bound
  // to the functionality of the Pressable component
  // (unlike onHoverIn and onHoverOut),
  // make sure that the hover logic still works the same.
  // Therefore, pause the hover handling when the component is disabled.
  const hoverHandlerProps = !disabled
    ? Platform.OS == "web"
      ? // Use the hidden onMouseEnter and onMouseLeave
        // event handlers on web platform,
        // as onHoverIn and onHoverOut ones
        // are not fully supported everywhere (e.g. mobile browsers).
        { onMouseEnter: onHoverIn, onMouseLeave: onHoverOut }
      : { onHoverIn, onHoverOut }
    : undefined

  // Do not include any of the supported and
  // potentially defined press handlers,
  // in case the Pressable should be disabled.
  // Otherwise, an action might be carried out
  // that should no longer be possible
  // (e.g. when the disabled property dynamically changes
  //       during an unfinished press event).
  const pressHandlerProps = !disabled
    ? { delayLongPress, onPress, onPressIn, onPressOut, onLongPress }
    : undefined

  return (
    <Pressable {...generalProps} {...hoverHandlerProps} {...pressHandlerProps}>
      {children}
    </Pressable>
  )
}
