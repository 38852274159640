import { isEqual } from "lodash"
import React from "react"

import { useSelection } from "~shared/components/providers/SelectionProvider"

import { InputRow } from "./InputRow"

type SingleSelectRowProps = {
  value: string
  displayKeyValuePairs: Record<string, string>
  onChange: (value: string) => void

  title?: string
  disabled?: boolean
  defaultOverwrite?: boolean
}

export function SingleSelectRow({
  title,
  value,
  defaultOverwrite,
  displayKeyValuePairs,
  onChange,
  ...props
}: SingleSelectRowProps) {
  const { selectSingle } = useSelection()

  const text =
    displayKeyValuePairs[value] ??
    (defaultOverwrite ? value : displayKeyValuePairs?.default)

  const disabled =
    props.disabled ?? isEqual(Object.keys(displayKeyValuePairs), ["default"])

  const items = Object.keys(displayKeyValuePairs)
  const onPress = async () => {
    const selectedValue = await selectSingle({
      title,
      items,
      selection: value,
      confirmMode: false,
      displayMapping: key => displayKeyValuePairs[key],
    })
    onChange(selectedValue)
  }

  return (
    <InputRow
      text={text}
      disabled={disabled}
      icon={{ name: "format-list-checkbox" }}
      onPress={onPress}
    />
  )
}
