import {
  isValidBIC as isITValidBIC,
  isValidIBAN as isITValidIBAN,
} from "ibantools"

// In case the passed value is undefined or
// the string representation of it is either empty or
// consists out of whitespaces only, this function will evaluate to 'true'.
export function isEmptyOrBlank(
  input: string | boolean | number | Big | undefined | null,
) {
  return input == undefined || input.toString().trim().length == 0
}

export function isIntegerNumberValid(input?: number | string): boolean {
  if (!input) return false
  return input.toString().match(/^\d+$/) != null
}

const dateTimeRegex =
  /^(?:(?:(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))) (0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]))$/
const dateRegex =
  /^(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))$/ // Checks if the given dateString is a valid date with the help of a regular expression
const timeRegex = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/

// Verifies dateTime format and validity of the dateTimeString
// (including leap year validation).
export function isValidDateTime(dateTimeString: string): boolean {
  return dateTimeString.match(dateTimeRegex) != null
}

// Verifies date format and validity of the dateString
// (including leap year validation).
export function isValidDate(dateString: string): boolean {
  return dateString.match(dateRegex) != null
}

// Verifies time format and validity of the timeString.
export function isValidTime(timeString: string): boolean {
  return timeString.match(timeRegex) != null
}

// Adapted and slightly customized based on:
// https://github.com/manishsaraan/email-validator/blob/master/index.js
const emailRegex =
  /^[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export function isEmailValid(email?: string): boolean {
  if (!email) return false

  const emailParts = email.split("@")
  if (emailParts.length !== 2) return false

  // Pre @-sign
  const userPart = emailParts[0]

  // Post @-sign
  const domainPart = emailParts[1]

  if (userPart.length > 64 || domainPart.length > 255) return false

  const domainParts = domainPart.split(".")
  if (domainParts.some(part => part.length > 63)) return false

  return emailRegex.test(email)
}

const phoneNumberRegex = /^[0-9+\-()\s]*$/

export function isPhoneNumberValid(phoneNumber?: string): boolean {
  if (!phoneNumber) return false

  if (phoneNumber.substring(1).includes("+")) return false

  return phoneNumber.match(phoneNumberRegex) != null
}

export function isZipCodeValid(zipCode?: string) {
  return zipCode && zipCode?.length >= 4
}

const uidRegex = "(A|a)(T|t)(U|u)\\d{8}"
const taxNumberRegex = "\\d{2}-\\d{3}/\\d{4}"

export function isTaxNumberValid(taxNumber?: string): boolean {
  if (!taxNumber) return false

  const uidNumberMatches = taxNumber.match(uidRegex)
  const taxNumberMatches = taxNumber.match(taxNumberRegex)

  return (
    (uidNumberMatches != null && uidNumberMatches[0] == taxNumber) ||
    (taxNumberMatches != null && taxNumberMatches[0] == taxNumber)
  )
}

export function isIBANValid(iban?: string): boolean {
  if (!iban) return false

  // 1. Extract the countryCode from the potential IBAN
  // (should always be represented by the first two characters).
  const countryCode = iban.substring(0, 2)

  // 2. In a second step we want
  // to extract the account identification.
  const accountIdentification = iban.substring(2)

  // 3. Remove all guideline violating characters of the identification.
  const cleanedAccountIdentification = accountIdentification.replace(/\D/g, "")

  // 4. Validate th cleaned IBAN.
  return isITValidIBAN(`${countryCode}${cleanedAccountIdentification}`)
}

export function isBICValid(bic?: string): boolean {
  if (!bic) return false
  const cleanedBIC = bic.replace(/[^0-9a-zA-Z]+/g, "")
  return isITValidBIC(cleanedBIC)
}

const moneyRegex = /^-?\d+[.|,]?\d?\d?$/

export function isEurosStringValid(moneyString: string): boolean {
  if (isEmptyOrBlank(moneyString)) return true
  return moneyString.match(moneyRegex) != null
}

// Other than initially specified and realised,
// the application needs to support a broader range of barcode standards.
// Because of that, explicit GTIN-only support and validation are dropped.
//
// Now numeric barcode numbers ranging from 4 to 48 characters are supported
// (e.g. GTIN [UPC, EAN, ITF; GTIN-8,-12,-13,-14],
//       Code 39 [ISO/IEC 16388:2007] and 128 [ISO/IEC 15417:2007], etc.).
//
// Limit explanation:
// - Lower limit: 4 -  In order to prevent accidental input and
//                     to comply with scanner processing tactics as well as
//                     consider the requirements of the customer,
//                     a barcode number must consist of a minimum of 4 digits.
//
// - Upper limit: 48 - Because it would not be very practicable
//                     to allow even larger possible barcode numbers
//                     (from a scanning/hardware standpoint) and
//                     the GS1-128 standard defines a limit of 48 chars,
//                     do apply the same limit too (https://www.gs1.at/gs1-128).
export function isBarcodeNumberValid(barcodeNumber?: string): boolean {
  if (!barcodeNumber) return false

  return (
    barcodeNumber.length >= 4 &&
    barcodeNumber.length <= 48 &&
    isIntegerNumberValid(barcodeNumber)
  )
}
