import React, { useMemo, useRef } from "react"
import { isMobile } from "react-device-detect"
import { ActivityIndicator, Platform, StyleSheet } from "react-native"

import {
  useDeviceInfo,
  useOnOff,
  useStateIfMounted,
  useSubsequentEffect,
} from "@axtesys/hooks"

import { Icon } from "../display/Icon"
import { MCIcon } from "../display/MCIcon"
import { useScreenSizeDependentValue } from "../hooks/useScreenSizeDependentValue"
import { ErrorField } from "../input/field/ErrorField"
import { Column, Row } from "../layout/FlexBox"
import { Theme, useTheme } from "../theme"
import { Label } from "../typography/Label"
import { PressableOpacity } from "./PressableOpacity"

export type ButtonProps = {
  // The main text / label of the button.
  text?: string

  // The sub text / label displayed below the main text / label.
  subText?: string

  // Icon which is displayed
  // on the left side of the label text.
  icon?: MCIcon

  // If true, the button uses
  // the full width of its container.
  fluid?: boolean

  // A button can show a pending state (ActivityIndicator),
  // and will be disabled once set to true.
  pending?: boolean

  // A button could also have no background color at all,
  // only the borders and the text are colored in that case.
  outlined?: boolean

  // A button can be set to be disabled, ignoring presses.
  // It will also be disabled if no onPress handler is present.
  disabled?: boolean

  // An error message to be displayed below the button.
  errorMessage?: string

  // Set this flag for important
  // buttons that initiate a new action.
  callToAction?: boolean

  // In case this flag is set, and we are on a larger screen,
  // use the maxWidth for the button.
  fluidOverwrite?: boolean

  // Will be called when an input device hovers over the button.
  onHoverIn?: () => void

  // Will be called when an input device leaves the area of the button.
  onHoverOut?: () => void

  // Default press handler that executes when the button is touched/clicked.
  onPress?: () => void | Promise<void>
}

export function Button(props: ButtonProps) {
  const { deviceType } = useDeviceInfo()
  const { spacing, color: themeColor } = useTheme()
  const { isPending, onPress } = usePendingState(props)
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)
  const [isPressed, onPressIn, onPressOut] = useOnOff(false)
  const maxWidth = useScreenSizeDependentValue(MAX_BUTTON_WIDTH)

  const isWeb = Platform.OS == "web"
  const padVertical = isWeb ? "XS" : "XXS"
  const isDisabled = props.disabled || isPending || !onPress
  const fluidOverwrite =
    props.fluid && props.fluidOverwrite && deviceType != "phone"
  const { color, labelColor, backgroundColor } = useColors(props, themeColor, {
    isPending,
    isDisabled,
    isHovered,
    isPressed,
  })

  const onHandleHoverIn = () => {
    onHoverIn()
    props.onHoverIn?.()
  }
  const onHandleHoverOut = () => {
    onHoverOut()
    props.onHoverOut?.()
  }

  useSubsequentEffect(() => {
    if (!isWeb || !(isPressed || isPending)) return
    onHandleHoverOut()
  }, [isPressed, isPending])

  const pendingIndicator = isPending && (
    <ActivityIndicator size={spacing.S} color={labelColor.custom} />
  )

  const icon = props.icon && !isPending && (
    <Icon size="S" name={props.icon} color={labelColor} />
  )

  const text = (props.text || props.subText) && (
    <Column alignCenter>
      <Label
        bold={!isWeb}
        text={props.text}
        textAlign="center"
        selectable={false}
        color={labelColor}
      />
      {props.subText && (
        <Label
          small
          textAlign="center"
          selectable={false}
          color={labelColor}
          text={props.subText}
        />
      )}
    </Column>
  )

  const button = (
    <PressableOpacity
      disabled={isDisabled}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onHoverIn={onHandleHoverIn}
      onHoverOut={onHandleHoverOut}
      onLongPress={isMobile ? onHandleHoverIn : undefined}
    >
      <Row
        alignCenter
        gap="XXXS"
        borderRadius="M"
        borderColor={color}
        padVertical={padVertical}
        padHorizontal={["XXS", "XS"]}
        style={[
          styles.buttonRow,
          isHovered && styles.hovered,
          isDisabled && styles.disabled,
        ]}
        backgroundColor={backgroundColor}
      >
        {pendingIndicator}
        {icon}
        {text}
      </Row>
    </PressableOpacity>
  )

  const buttonWithErrorHandling = props.errorMessage ? (
    <ErrorField errorMessage={props.errorMessage}>{button}</ErrorField>
  ) : (
    button
  )

  return (
    <Column
      style={[
        props.fluid && styles.fluid,
        (!props.fluid || fluidOverwrite) && { maxWidth },
      ]}
    >
      {buttonWithErrorHandling}
    </Column>
  )
}

// Used to manage the pending (color and disabled state)
// and onPress props for the button.
function usePendingState({ pending, onPress }: ButtonProps) {
  const pendingRef = useRef(false)
  const [isPending, setIsPending] = useStateIfMounted(false)

  return {
    // `pending` prop should override the internal state.
    isPending: pending ?? isPending,

    // Disable `onPress` while the previous onPress handler is still running
    // to prevent additional action executions during that time.
    onPress:
      onPress && !isPending
        ? async () => {
            if (pendingRef.current) return

            try {
              pendingRef.current = true
              setIsPending(true)
              await Promise.resolve(onPress())
            } finally {
              pendingRef.current = false
              setIsPending(false)
            }
          }
        : undefined,
  }
}

function useColors(
  { outlined, callToAction }: ButtonProps,
  themeColor: Theme["color"],
  flags: {
    isDisabled: boolean
    isPressed: boolean
    isPending: boolean
    isHovered: boolean
  },
) {
  const { accent, primary, button, background } = themeColor

  const color = useMemo(() => {
    const { isHovered, isPressed, isPending, isDisabled } = flags
    const variants = callToAction ? button.callToAction : button.default

    let color: string
    if (isDisabled) color = variants.disabled
    else if (isPressed || isPending) color = variants.pressed
    else if (isHovered) color = variants.hovered
    else color = variants.default

    return color
  }, [flags, callToAction, button.callToAction, button.default])

  const backgroundColor = !outlined ? color : undefined
  const labelColor = {
    custom: outlined ? (callToAction ? accent : primary) : background,
  }

  return { color, labelColor, backgroundColor }
}

// Custom maxWidth for the button and its error message,
// depending on the screen size of the used device.
const MAX_BUTTON_WIDTH = { S: 250, M: 300, L: 350 }

const styles = StyleSheet.create({
  fluid: { width: "100%" },
  hovered: { opacity: 0.8 },
  disabled: { opacity: 0.4 },
  buttonRow: {
    height: 50,
    borderWidth: 2,
    maxWidth: "100%",
    justifyContent: "center",
  },
})
