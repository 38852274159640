import { map, mapValues, merge, orderBy, reduce } from "lodash"
import * as z from "zod"

import { isEmptyOrBlank, jsonDbAtom, listToRecord } from "@axtesys/react-tools"

import { selector } from "~shared/lib/recoil/lib"
import {
  Article,
  ArticleEntity,
  ArticleGroup,
  ArticleGroupEntity,
  ArticleGroupId,
  ArticleId,
  CashFlow,
  CashFlowEntity,
  CompanyDataEntity,
  ContactData,
  ContactDataEntity,
  ContactId,
  Discount,
  DiscountEntity,
  KeyboardLayoutContentEntity,
  MainGroup,
  MainGroupEntity,
  MainGroupId,
  PaymentMethod,
  PaymentMethodEntity,
  ReceiptDesignEntity,
  SharedOptionalReceiptDesign,
  SpecificKeyboardLayoutContent,
} from "~shared/types"

export const companyState = jsonDbAtom({
  key: "companyInfoState",
  default: {
    companyId: "",
    name: "",
    taxId: "",
    manager: { managerId: "", email: "", phoneNumber: "" },
    address: { street: "", streetNumber: "", zipCode: "", city: "" },
  },
  schema: CompanyDataEntity.schema,
  serialize: CompanyDataEntity.serialize,
  deserialize: CompanyDataEntity.deserialize,
})

export const taxOfficeCashBoxIdState = jsonDbAtom({
  key: "taxOfficeCashBoxIdState",
  default: "maxundben",
  schema: z.string(),
})

export const taxOfficeCashBoxIdSelector = selector({
  key: "taxOfficeCashBoxIdSelector",
  get: ({ get }) =>
    get(isRksvEnabledState) ? get(taxOfficeCashBoxIdState) : undefined,
})

export const closeOfDayTimeState = jsonDbAtom({
  key: "closeOfDayTime",
  default: "00:00",
  schema: z.string(),
})

export const isRksvEnabledState = jsonDbAtom({
  key: "isRksvEnabled",
  default: false,
  schema: z.boolean(),
})

export const receiptDesignState = jsonDbAtom({
  key: "receiptDesign",
  default: {} as SharedOptionalReceiptDesign,
  schema: ReceiptDesignEntity.schema,
})

export const keyboardLayoutState = jsonDbAtom({
  key: "keyboardLayoutState",
  default: undefined as SpecificKeyboardLayoutContent | undefined,
  schema: KeyboardLayoutContentEntity.schema,
  serialize: KeyboardLayoutContentEntity.serialize,
  deserialize: KeyboardLayoutContentEntity.deserialize,
})

export const mainGroupsState = jsonDbAtom({
  key: "mainGroupsState",
  default: {} as Record<MainGroupId, MainGroup>,
  schema: z.record(MainGroupEntity.schema),
})

// All mainGroups ordered by their orderIndex
export const mainGroupsOrderedSelector = selector({
  key: "mainGroupsOrdered",
  get: ({ get }) =>
    orderBy<MainGroup>(
      Object.values(get(mainGroupsState)),
      ["orderIndex"],
      ["asc"],
    ),
})

export const articleGroupsState = jsonDbAtom({
  key: "articleGroupsState",
  default: {} as Record<ArticleGroupId, ArticleGroup>,
  schema: z.record(ArticleGroupEntity.schema),
})

// All articleGroups ordered by their orderIndex
export const articleGroupsOrderedSelector = selector({
  key: "articleGroupsOrdered",
  get: ({ get }) =>
    orderBy<ArticleGroup>(
      Object.values(get(articleGroupsState)),
      ["orderIndex"],
      ["asc"],
    ),
})

export const articlesState = jsonDbAtom({
  key: "articlesState",
  default: {} as Record<ArticleId, Article>,
  schema: z.record(ArticleEntity.schema),
  serialize: data => mapValues(data, ArticleEntity.serialize),
  deserialize: json => mapValues(json, ArticleEntity.deserialize),
})

export const barcodeNumberArticleIdsSelector = selector({
  key: "barcodeNumberArticleIds",
  get: ({ get }) =>
    reduce(
      get(articlesState),
      (barcodeIdRecord, article) => {
        for (const barcodeNumber of article.barcodeNumbers ?? []) {
          if (isEmptyOrBlank(barcodeNumber)) continue
          barcodeIdRecord[barcodeNumber] = article.articleId
        }
        return barcodeIdRecord
      },
      {} as Record<string, ArticleId>,
    ),
})

export const identifierArticleIdsSelector = selector({
  key: "identifierArticleIds",
  get: ({ get }) =>
    merge(
      {},
      reduce(
        get(articlesState),
        (articleNumberIdRecord, article) => {
          if (isEmptyOrBlank(article.number)) return articleNumberIdRecord
          articleNumberIdRecord[article.number!] = article.articleId
          return articleNumberIdRecord
        },
        {} as Record<string, ArticleId>,
      ),
      get(barcodeNumberArticleIdsSelector),
    ),
})

export const predefinedDiscountsState = jsonDbAtom({
  key: "predefinedDiscountsState",
  default: [] as Discount[],
  schema: z.array(DiscountEntity.schema),
  serialize: data => map(data, DiscountEntity.serialize),
  deserialize: json => map(json, DiscountEntity.deserialize),
})

export const paymentMethodsState = jsonDbAtom({
  key: "paymentMethodsState",
  default: [] as PaymentMethod[],
  schema: z.array(PaymentMethodEntity.schema),
  serialize: data => map(data, PaymentMethodEntity.serialize),
  deserialize: json => map(json, PaymentMethodEntity.deserialize),
})

export const paymentMethodRecordSelector = selector({
  key: "paymentMethodRecord",
  get: ({ get }) =>
    listToRecord(
      get(paymentMethodsState),
      paymentMethod => paymentMethod.paymentMethodId,
      paymentMethod => paymentMethod,
    ),
})

export const cashFlowsState = jsonDbAtom({
  key: "cashFlowsState",
  default: [] as CashFlow[],
  schema: z.array(CashFlowEntity.schema),
})

export const contactsState = jsonDbAtom({
  key: "contactsState",
  default: {} as Record<ContactId, ContactData>,
  schema: z.record(ContactDataEntity.schema),
  serialize: data => mapValues(data, ContactDataEntity.serialize),
  deserialize: json => mapValues(json, ContactDataEntity.deserialize),
})
