import React, { ReactNode } from "react"
import { Platform } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { PressableOpacity } from "../button/PressableOpacity"
import { Box } from "../layout/Box"
import { Color } from "../types"
import { Label, LabelProps } from "../typography/Label"
import { MCIcon } from "./MCIcon"

const MAX_WIDTH = 175

type WidthOverwriteProps = {
  // Overwrites the default MAX_WIDTH
  // of the tooltip (box width; web only).
  widthOverwrite?: number
}

export type ToolTipIconProps = WidthOverwriteProps & {
  // The name of the icon that should be displayed.
  icon?: MCIcon

  // The color the displayed icon should have.
  color?: Color

  // The title displayed above
  // the content of the tooltip (dialog title; native only).
  title?: string

  // The size the icon should have.
  size?: "XS" | "S" | "M" | "L" | "XL"
} & ({ text: string } | { children: ReactNode })

type ToolTipProps = WidthOverwriteProps & {
  // The node the tooltip component should wrap (e.g. icon).
  children: ReactNode

  // The content of the tooltip (either text or a node).
  text: string | ReactNode
}

// A tooltip component specifically crafted for usage on the web platform.
export function ToolTip({ text, children, widthOverwrite }: ToolTipProps) {
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  const width = widthOverwrite ?? MAX_WIDTH
  const content = typeof text == "string" ? <Label medium text={text} /> : text

  return (
    <PressableOpacity
      alwaysActive
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
    >
      {children}

      {isHovered && (
        <Box style={{ width, position: "absolute" }}>
          <Box
            style={{
              left: 22,
              bottom: 0,
              maxWidth: width,
              position: "absolute",
            }}
          >
            {content}
          </Box>
        </Box>
      )}
    </PressableOpacity>
  )
}

export function SubTitleTooltipLabel({ text }: Pick<LabelProps, "text">) {
  return <Label medium={Platform.OS == "web"} text={text} />
}

export function MessageTooltipLabel({ text }: Pick<LabelProps, "text">) {
  const isWeb = Platform.OS == "web"

  return <Label small={isWeb} medium={!isWeb} text={text} />
}
